import React, { useState, useEffect } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min"
import Logo from "../img/background-photo/logo.png-removebg-preview.png";
import vect from "../img/background-photo/no-background.png";
import Book from "../img/our-sevices/book set.png";
import Bouch from "../img/our-sevices/brochures.png";
import Calender from "../img/our-sevices/calender.png";
import Cate from "../img/our-sevices/catalogues.png";
import Docum from "../img/our-sevices/DOCUMENT + BINDING.png";
import Inve from "../img/our-sevices/invitation card.png";
import Menu from "../img/our-sevices/menu card.png";
import Pamp from "../img/our-sevices/pamplate.png";
import Poster from "../img/our-sevices/poster.png";
import Stick from "../img/our-sevices/sticker.png";
import Tag from "../img/our-sevices/tags.png";
import Visi from "../img/our-sevices/visiting card.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import "../css/port.css"; // Ensure your CSS file includes styles for .loader and related classes

const Port = () => {
  const handlleClick = () => {

    // whats up icon
    window.open('https://wa.me/919503551157', '_blank'); // Replace with your WhatsApp number
  };

  // port code....
  const [selectedGallery, setSelectedGallery] = useState("gallery1");
  const galleries = {
    gallery1: [
      require("../img/portfolio/first.jpg"),
      require("../img/portfolio/second.jpg"),
      require("../img/portfolio/third.jpg"),
      require("../img/portfolio/four.jpg"),
    ],
    gallery2: [
      require("../img/portfolio/visiting1.jpg"),
      require("../img/portfolio/visiting2.jpg"),
      require("../img/portfolio/visiting3.jpg"),
      require("../img/portfolio/visiting4.jpg"),
    ],
    gallery3: [
      require("../img/portfolio/icard1.jpg"),
      require("../img/portfolio/icard2.jpg"),
      require("../img/portfolio/icard3.jpg"),
      require("../img/portfolio/icard4.jpg"),
    ],
    gallery4: [
      require("../img/portfolio/led-frem1.jpg"),
      require("../img/portfolio/led-frem2.jpg"),
      require("../img/portfolio/led-frem3.jpg"),
      require("../img/portfolio/led-frem4.jpg"),
    ],
    gallery5: [
      require("../img/portfolio/flex1.jpg"),
      require("../img/portfolio/flex2.jpg"),
      require("../img/portfolio/flex3.jpg"),
      require("../img/portfolio/flex4.jpg"),
    ],
    gallery6: [
      require("../img/portfolio/mom1.jpg"),
      require("../img/portfolio/mom2.jpg"),
      require("../img/portfolio/mom3.jpg"),
      require("../img/portfolio/mom4.jpg"),
    ],
    gallery7: [
      require("../img/portfolio/banner1.jpg"),
      require("../img/portfolio/banner2.jpg"),
      require("../img/portfolio/banner3.jpg"),
      require("../img/portfolio/banner4.jpg"),
    ],
  };

  const handleButtonClick = (galleryKey) => {
    setSelectedGallery(galleryKey);
  };

//  our services 
const [selectedService, setSelectedService] = useState(null);

const handleClick = (service) => {
  setSelectedService(service);
};

const handleClose = () => {
  setSelectedService(null);
};



  const services = [
    {
      id: 3,
      title: "Books",
      description: "Some animated book.",
      image: Book,
    },
    {
      id: 3,
      title: "Poster",
      description: "Some animated poster.",
      image: Bouch,
    },
    {
      id: 3,
      title: "Calender",
      description: "Some Calender we provide",
      image: Calender,
    },
    {
      id: 4,
      title: "Pamplates",
      description: "Some animated pamplates.",
      image: Cate,
    },
    {
      id: 5,
      title: "Documents",
      description: "Documents Binding.",
      image: Docum,
    },
    {
      id: 6,
      title: "Invitation ",
      description: "cards we provide.",
      image: Inve,
    },
    {
      id: 7,
      title: "Menu cards",
      description: "menu cards available.",
      image: Menu,
    },
    {
      id: 8,
      title: "Acralic",
      description: "Some animated text for acralic printing.",
      image: Pamp,
    },
    {
      id: 9,
      title: "Poster",
      description: "Some animated text for poster printing.",
      image: Poster,
    },
    {
      id: 10,
      title: "Stickers",
      description: "we provide expensive stickers.",
      image: Stick,
    },
    {
      id: 11,
      title: "Tags",
      description: "we provide.",
      image: Tag,
    },
    {
      id: 12,
      title: "Visiting",
      description: "cards we provide.",
      image: Visi,
    },
  ];

  // contact code...

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    pincode: "",
    phone: "",
    message: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  //  font text 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer);
  }, []);

  //  nav form 
  const [showLoginForm, setShowLoginForm] = useState(false);

  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  return (
    <div>


      {loading ? (
        <div className="loader">
          <svg height="0" width="0" viewBox="0 0 64 64" className="absolute">
            <defs xmlns="http://www.w3.org/2000/svg">
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="b"
              >
                <stop stopColor="#973BED"></stop>
                <stop stopColor="#007CFF" offset="1"></stop>
              </linearGradient>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="0"
                x2="0"
                y1="64"
                x1="0"
                id="c"
              >
                <stop stopColor="#FFC800"></stop>
                <stop stopColor="#F0F" offset="1"></stop>
                <animateTransform
                  repeatCount="indefinite"
                  keySplines=".42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
                  keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                  dur="8s"
                  values="0 32 32;-270 32 32;-270 32 32;-540 32 32;-540 32 32;-810 32 32;-810 32 32;-1080 32 32;-1080 32 32"
                  type="rotate"
                  attributeName="gradientTransform"
                ></animateTransform>
              </linearGradient>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="d"
              >
                <stop stopColor="#00E0ED"></stop>
                <stop stopColor="#00DA72" offset="1"></stop>
              </linearGradient>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="8"
              stroke="url(#b)"
              d="M 54.722656,3.9726563 A 2.0002,2.0002 0 0 0 54.941406,4 h 5.007813 C 58.955121,17.046124 49.099667,27.677057 36.121094,29.580078 a 2.0002,2.0002 0 0 0 -1.708985,1.978516 V 60 H 29.587891 V 31.558594 A 2.0002,2.0002 0 0 0 27.878906,29.580078 C 14.900333,27.677057 5.0448787,17.046124 4.0507812,4 H 9.28125 c 1.231666,11.63657 10.984383,20.554048 22.6875,20.734375 a 2.0002,2.0002 0 0 0 0.02344,0 c 11.806958,0.04283 21.70649,-9.003371 22.730469,-20.7617187 z"
              className="dash"
              id="y"
              pathLength="360"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ "--rotation-duration": "0ms", "--rotation-direction": "normal" }}
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="10"
              stroke="url(#c)"
              d="M 32 32 m 0 -27 a 27 27 0 1 1 0 54 a 27 27 0 1 1 0 -54"
              className="spin"
              id="o"
              pathLength="360"
            ></path>
          </svg>
          <div className="w-2"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ "--rotation-duration": "0ms", "--rotation-direction": "normal" }}
            viewBox="0 0 64 64"
            height="64"
            width="64"
            className="inline-block"
          >
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="8"
              stroke="url(#d)"
              d="M 4,4 h 4.6230469 v 25.919922 c -0.00276,11.916203 9.8364941,21.550422 21.7500001,21.296875 11.616666,-0.240651 21.014356,-9.63894 21.253906,-21.25586 a 2.0002,2.0002 0 0 0 0,-0.04102 V 4 H 56.25 v 25.919922 c 0,14.33873 -11.581192,25.919922 -25.919922,25.919922 a 2.0002,2.0002 0 0 0 -0.0293,0 C 15.812309,56.052941 3.998433,44.409961 4,29.919922 Z"
              className="dash"
              id="u"
              pathLength="360"
            ></path>
          </svg>
          <div className="text-container">
            <h1 className="animated-text">PRINT FOR U</h1>
          </div>
        </div>
      ) : (
        <div>

          {/* Main website content goes here */}


          <button
      onClick={handlleClick}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp} size="3x" color="#25D366" />
      <span style={{ fontSize: '16px', color: '#25D366' }}>Contact Us</span>
    </button>
    


{/*  navigation */}
<nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src={Logo}
              alt="Company Logo"
              className="d-inline-block align-text-top"
              style={{ borderRadius: "4px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    


      {/* animated text & vector */}
      
      <section className="home" id="home">
  <div className="print-vect row">
    <div className="print-row col-md-6" >
      <div className="col-12 text-center">
        <h3 className="animate-charcter">Print For You</h3>
        <h3 className="animate-charcter">
          Premium printing <br /> Anytime
          Anywhere...!
        </h3>
      </div>
    </div>
    <section className="col-md-6 IMGMAIN">
      <div className="col-12" style={{ marginRight: '20px' }}>
        <img
          src={vect}
          alt="SEO"
          className="vect"
 
        />
      </div>
    </section>
  </div>
</section>




      {/* About us */}

  <div className="container-fluid" id="about">
  <div className="animated-section d-flex align-items-center justify-content-center text-center">
    <div className="content p-4">
      <h1 className="welcome">
        Welcome to <span style={{ color: "#ecfd2e" }}>Printforyou</span>
      </h1>
      <p className="mt-4">
        Your one-stop solution for all your printing needs. We offer a
        comprehensive range of printing services, both online and offline,
        designed to bring your creative ideas to life. At Printforyou, we
        understand the importance of quality and timing. That’s why we are
        committed to delivering creative and attractive designs on time. Our
        team of experienced designers and printing experts work closely with you
        to ensure that your vision is perfectly captured in every print. We take
        pride in our ability to cater to both individuals and businesses,
        offering customized solutions that meet your specific needs. Whether
        you’re looking for a one-off print or bulk orders, our state-of-the-art
        technology and efficient processes ensure that you receive the highest
        quality prints with a quick turnaround. Join us at Printforyou, where
        your print is our priority. Let us help you make a lasting impression
        with designs that stand out and prints that speak volumes.
      </p>
    </div>
  </div>
</div>


      {/* service */}

      <section className="services" id="services">
  <div className="SERVICE-overlay">
    <h1 className="text-center mb-5 mt-5 text-white">
      OUR <span style={{ color: "#ecfd2e" }}>SERVICES</span>
    </h1>
    <div className="container mt-5">
      <div className="row">
        {services.map((service) => (
          <div key={service.id} className="col-md-6 col-lg-4 mb-4">
            <div className="service-box" onClick={() => handleClick(service)}>
              <div className="service-background">
                <img
                  src={service.image}
                  alt={service.title}
                  className="service-image img-fluid"
                />
              </div>
              <div className="hover-text">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      {selectedService && (
        <div className="service-detail-overlay">
          <div className="service-detail-box">
         
            
            <div className="service-detail-content">
              <h3>{selectedService.title}</h3>
              <img
                src={selectedService.image}
                alt={selectedService.title}
                className="service-detail-image img-fluid"
              />
              <p>{selectedService.description}</p>
              <button className="close-btn" onClick={handleClose}>
              Close </button>
              <button className="enquiry-btn btn btn-primary" style={{marginLeft :'15px',marginBottom:'15px'}}>Enquire</button>
            </div>
            
          </div>
        </div>
      )}
    </div>
  </div>
</section>


      {/* portfolio */}

    
      <section className="portfolio" id="portfolio">
  <h1 className="text-center mb-5 mt-5 text-white">
    OUR <span style={{ color: "#ecfd2e" }}> PORTFOLIO</span>
  </h1>

  {/* Portfolio Buttons */}
  
  <div className="d-flex flex-wrap justify-content-center mb-5 portfolio">
  <button
    className="btn btn-primary mx-2 my-2 visiting-cards"
    onClick={() => handleButtonClick("gallery1")}
  >
    Visiting Cards
  </button>
  <button
    className="btn btn-primary mx-2 my-2 invitation-cards"
    onClick={() => handleButtonClick("gallery2")}
  >
    Invitation Cards
  </button>
  <button
    className="btn btn-primary mx-2 my-2 id-cards"
    onClick={() => handleButtonClick("gallery3")}
  >
    Id Cards
  </button>
  <button
    className="btn btn-primary mx-2 my-2 led-frames"
    onClick={() => handleButtonClick("gallery4")}
  >
    Led Frames
  </button>
  <button
    className="btn btn-primary mx-2 my-2 flex"
    onClick={() => handleButtonClick("gallery5")}
  >
    Flex
  </button>
  <button
    className="btn btn-primary mx-2 my-2 momentos"
    onClick={() => handleButtonClick("gallery6")}
  >
    Momentos
  </button>
  <button
    className="btn btn-primary mx-2 my-2 roll-up-banner"
    onClick={() => handleButtonClick("gallery7")}
  >
    Roll up Banner
  </button>
</div>


  {/* Portfolio Images */}

  <div className="container">
    <div className="row">
      {galleries[selectedGallery]?.map((image, index) => (
        <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
          <img
            src={image}
            alt={`Gallery ${selectedGallery} Image ${index + 1}`}
            className="img-fluid zoom-effect"
          />
        </div>
      ))}
    </div>
  </div>
</section>


      {/* contact  */}

      <section id="contact" className="pt-5">
        <div className="CONTACT-container">
          <h1 className="text-center  mt-5 text-blue">
            GET IN <span style={{ color: "#ecfd2e" }}>TOUCH</span>
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Name Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Email Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Address Field */}
              <div className="col-md-12 mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Your Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Pincode Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="pincode" className="form-label">
                  Pincode
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pincode"
                  placeholder="Your Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Phone Number Field */}
              <div className="col-md-6 mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              {/* Message Field */}
              <div className="col-md-12 mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="5"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button type="submit" className="btn btn-skyblue">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

   

      {/* footer  */}

      <footer className="pt-4 bg-white text-dark">
  <div className="container pt-4">
    <div className="row">
      {/* Company Information */}
      <div className="col-12 col-md-3 mb-4">
        <h5>
          <a href="#">
            <img
              className="custom-logo"
              src={Logo}
              alt="Company Logo"
              style={{ width: "100px" }}
            />
          </a>
        </h5>
        <p className="text-justify">
          We take pride in our ability to cater to both individuals and businesses, offering customized solutions that meet your specific needs.
        </p>
      </div>
      {/* Quick Links */}
      <div className="col-12 col-md-3 mb-4 " style={{textAlign:'center'}}>
        <h5 className="text-danger">Quick Links</h5>
        <ul className="list-unstyled pl-0">
          <li><a href="#home" className="text-dark">Home</a></li>
          <li><a href="#about" className="text-dark">About Us</a></li>
          <li><a href="#services" className="text-dark">Services</a></li>
          <li><a href="#portfolio" className="text-dark">Portfolio</a></li>
          <li><a href="#contact" className="text-dark">Contact</a></li>
        </ul>
      </div>
      {/* Additional Links */}
      <div className="col-12 col-md-3 mb-4" style={{textAlign:'center'}}>
        <h5 className="text-danger">Additional Links</h5>
        <ul className="list-unstyled">
          <li><a href="./desclaimer.html" className="text-dark">Disclaimer</a></li>
          <li><a href="./privacy.html" className="text-dark">Privacy Policy</a></li>
          <li><a href="./refund.html" className="text-dark">Refund Policy</a></li>
          <li><a href="./terms.html" className="text-dark">Terms and Conditions</a></li>
        </ul>
      </div>
      {/* Contact Information */}
      <div className="col-12 col-md-3 mb-4" style={{textAlign:'center'}}>
        <h5 className="text-danger">Contact</h5>
        <ul className="list-unstyled">
          <li><i className="fas fa-map-marker-alt mr-2"></i>123 Street, City, Country</li>
          <li><i className="fas fa-phone mr-2"></i>+123 456 7890</li>
          <li><i className="fas fa-envelope mr-2"></i>Printforyou@company.com</li>
        </ul>
        {/* Social Media Icons */}
        <div className="mt-3">
          <a href="#" className="text-dark mr-3"><i className="fab fa-facebook-f"></i></a>
          <a href="#" className="text-dark mr-3"><i className="fab fa-twitter"></i></a>
          <a href="#" className="text-dark mr-3"><i className="fab fa-linkedin-in"></i></a>
          <a href="#" className="text-dark"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
  </div>

     {/* top button */}

     <a href="#top" className="back-to-top-link">
      <button className="button">
        <svg className="svgIcon" viewBox="0 0 384 512">
          <path
            d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
          ></path>
        </svg>
      </button>
    </a>
  <div className="text-center py-3 mt-3 bg-gray text-dark">
    <p className="mb-0">&copy; 2024 Print for you. All rights reserved.</p>
  </div>
</footer>
      </div>
      )}
    </div>
  );
};

export default Port;
